
// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}
.form-secion {
    font-weight: bold;
}

.form-control-view{
  border: none !important;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  color:rgb(76, 125, 230);
}
.alert-custom {
  color:  #ba202e;
  background-color: none;
  border-color: none;
}

.remove-icon {
  color: #ba202e;
  cursor: pointer;
}
::ng-deep .modal-xl .modal-dialog {
  max-width: 100%;
  width: 100%;
}
